import { useEffect, useRef, useState } from "react";
import { EmailBuilder } from "../../components";
import defaultBlockList from "./defaultBlockList.json";
import { Header } from "././components";
import "./styles.css";

function Dashboard() {
  const emailEditorRef = useRef(null);
  const [emailData, setEmailData] = useState(null);
  const [language, setLanguage] = useState("en");

  useEffect(() => {
    setTimeout(() => {
      setEmailData(defaultBlockList);
    }, 300);
  }, []);

  return (
    <div className="dashboard">
      <Header emailEditorEl={emailEditorRef} setLanguage={setLanguage} />
      <div className="dashboard-content">
        {
          emailData && <EmailBuilder  ref={emailEditorRef} defaultBlockList={emailData} language={language} />
        }
      </div>
    </div>
  );
}

export default Dashboard;
