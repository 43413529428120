export const SET_BLOCK_LIST = "SET_BLOCK_LIST";
export const SET_IS_DRAG_START = "SET_IS_DRAG_START";
export const SET_ACTION_TYPE = "SET_ACTION_TYPE";
export const SET_PREVIEW_MODE = "SET_PREVIEW_MODE";
export const SET_CURRENT_ITEM = "SET_CURRENT_ITEM";
export const SET_BODY_SETTINGS = "SET_BODY_SETTINGS";
export const SET_SELECTION_RANGE = "SET_SELECTION_RANGE";
export const SET_TEXT_RANGE = "SET_TEXT_RANGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const SET_LANGUAGE_LIBRARIES = "SET_LANGUAGE_LIBRARIES";
