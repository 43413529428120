import {
  SET_BLOCK_LIST,
  SET_IS_DRAG_START,
  SET_ACTION_TYPE,
  SET_PREVIEW_MODE,
  SET_CURRENT_ITEM,
  SET_BODY_SETTINGS,
  SET_SELECTION_RANGE,
  SET_TEXT_RANGE,
  SET_LANGUAGE,
  SET_LANGUAGE_LIBRARIES,
} from "./constants.js";

export const setTextRange = (textRange) => {
  return {
    type: SET_TEXT_RANGE,
    textRange: textRange,
  };
};

export const setSelectionRange = (selectionRange) => {
  return {
    type: SET_SELECTION_RANGE,
    selectionRange: selectionRange,
  };
};

export const setBlockList = (blockList) => {
  return {
    type: SET_BLOCK_LIST,
    blockList: blockList,
  };
};

export const setIsDragStart = (isDragStart) => {
  return {
    type: SET_IS_DRAG_START,
    isDragStart: isDragStart,
  };
};

export const setActionType = (actionType) => {
  return {
    type: SET_ACTION_TYPE,
    actionType: actionType,
  };
};

export const setPreviewMode = (previewMode) => {
  return {
    type: SET_PREVIEW_MODE,
    previewMode: previewMode,
  };
};

export const setCurrentItem = (currentItem) => {
  return {
    type: SET_CURRENT_ITEM,
    currentItem: currentItem,
  };
};

export const setBodySettings = (bodySettings) => {
  return {
    type: SET_BODY_SETTINGS,
    bodySettings: bodySettings,
  };
};

export const setLanguage = (language) => {
  return {
    type: SET_LANGUAGE,
    language: language,
  };
};

export const setLanguageLibraries = (languageLibraries) => {
  return {
    type: SET_LANGUAGE_LIBRARIES,
    languageLibraries: languageLibraries,
  };
};
