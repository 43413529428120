import { createContext } from "react";
import {
  SET_BLOCK_LIST,
  SET_IS_DRAG_START,
  SET_ACTION_TYPE,
  SET_PREVIEW_MODE,
  SET_CURRENT_ITEM,
  SET_BODY_SETTINGS,
  SET_SELECTION_RANGE,
  SET_TEXT_RANGE,
  SET_LANGUAGE,
  SET_LANGUAGE_LIBRARIES,
} from "./constants.js";
export {
  setBlockList,
  setActionType,
  setPreviewMode,
  setCurrentItem,
  setBodySettings,
  setIsDragStart,
  setSelectionRange,
  setTextRange,
  setLanguage,
  setLanguageLibraries,
} from "./actions";

export const defaultLanguage = "en";
export const defaultBlockList = [];
export const defaultIsDragStart = false;
export const defaultActionType = "firstRender"; // firstRender, add, move, delete,edit, set_history_${index}, edit_${Date.now()}
export const defaultPreviewMode = "desktop";
export const defaultCurrentItem = null;
export const defaultBodySettings = {
  preHeader: "",
  contentWidth: 600,
  styles: {
    backgroundColor: "#F9F9F9",
    color: "#000",
    fontFamily: "Arial",
  },
};
export const defaultSelectionRange = null;
export const textRange = null;
export const defaultLanguageLibraries = {};

export const defaultState = {
  blockList: defaultBlockList,
  isDragStart: defaultIsDragStart,
  actionType: defaultActionType,
  previewMode: defaultPreviewMode,
  currentItem: defaultCurrentItem,
  bodySettings: defaultBodySettings,
  selectionRange: defaultSelectionRange,
  textRange: textRange,
  language: defaultLanguage,
  languageLibraries: defaultLanguageLibraries,
};

export const GlobalContext = createContext();

export function reducer(state, action) {
  switch (action.type) {
    case SET_BLOCK_LIST:
      return {
        ...state,
        blockList: action.blockList,
      };
    case SET_IS_DRAG_START:
      return {
        ...state,
        isDragStart: action.isDragStart,
      };
    case SET_ACTION_TYPE:
      return {
        ...state,
        actionType: action.actionType,
      };
    case SET_PREVIEW_MODE:
      return {
        ...state,
        previewMode: action.previewMode,
      };
    case SET_CURRENT_ITEM:
      return {
        ...state,
        currentItem: action.currentItem,
      };
    case SET_BODY_SETTINGS:
      return {
        ...state,
        bodySettings: action.bodySettings,
      };
    case SET_SELECTION_RANGE:
      return {
        ...state,
        selectionRange: action.selectionRange,
      };
    case SET_TEXT_RANGE:
      return {
        ...state,
        textRange: action.textRange,
      };
    case SET_LANGUAGE:
      return {
        ...state,
        language: action.language,
      };
    case SET_LANGUAGE_LIBRARIES:
      return {
        ...state,
        languageLibraries: action.languageLibraries,
      };
    default:
      return state;
  }
}
