import TextBlock from "./TextBlock";
import HeadingBlock from "./HeadingBlock";
import ButtonBlock from "./ButtonBlock";
import DividerBlock from "./DividerBlock";
import ImageBlock from "./ImageBlock";
import SocialLinkBlocks from "./SocialLinkBlocks";

const blockComponents = {
  text: TextBlock,
  heading: HeadingBlock,
  button: ButtonBlock,
  divider: DividerBlock,
  image: ImageBlock,
  social_link: SocialLinkBlocks,
};

const BlockItems = ({ blockItem, index }) => {
  const BlockComponent = blockComponents[blockItem?.key];

  return BlockComponent ? (
    <BlockComponent blockItem={blockItem} index={index} />
  ) : null;
};

export default BlockItems;
