import { useEffect, useRef, useContext } from "react";
import { GlobalContext } from "../../reducers";
import { deepClone } from "../../utils/helpers";
import { motion } from "framer-motion";

import Bold from "./Bold";
import Italic from "./Italic";
import InsertOrderedList from "./InsertOrderedList";
import InsertUnorderedList from "./InsertUnorderedList";

const RichText = ({ index, textBlock, styles }) => {
  const { blockList, setBlockList, currentItem, setCurrentItem } = useContext(GlobalContext);
  const richTextRef = useRef(null);

  useEffect(() => {
    if (richTextRef.current) {
      var viewportOffset = richTextRef.current.parentNode?.getBoundingClientRect();
      const preview = document.querySelector("#preview");
      const previewOffset = preview.getBoundingClientRect();
      if (viewportOffset.top - 190 < 0) {
        richTextRef.current.style.bottom = "auto";
        richTextRef.current.style.top = "110%";
      } else {
        richTextRef.current.style.bottom = "110%";
        richTextRef.current.style.top = "auto";
      }
      if (viewportOffset.left + 375 > previewOffset.left + previewOffset.width) {
        richTextRef.current.style.left = "auto";
        richTextRef.current.style.right = "0";
      } else {
        richTextRef.current.style.left = "0";
        richTextRef.current.style.right = "auto";
      }
    }
  }, []);

  const modifyText = (command, defaultUi, value) => {
    document.execCommand(command, defaultUi, value);
  };

  const setTextContent = () => {
    const indexArray = index.split("-");
    let newBlockList = deepClone(blockList);
    let newCurrentItem = deepClone(currentItem);
    newCurrentItem.data.text = textBlock.current.innerHTML;
    newBlockList[indexArray[0]].children[indexArray[1]].children[indexArray[2]].text = textBlock.current.innerHTML;
    setCurrentItem({ ...newCurrentItem });
    setBlockList(newBlockList);
  };

  return (
    <div
      className="rich-text"
      ref={richTextRef}
      onClick={(event) => {
        event.preventDefault();
        event.stopPropagation();
      }}
    >
      <motion.div
        className="rich-text-tools"
        initial={{ scale: 0, x: 0 }}
        animate={{ scale: 1, x: 0 }}
        style={{ width: "auto" }}
      >
        <div className="rich-text-tools-body items-center">
          <Bold modifyText={modifyText} setTextContent={setTextContent} />
          <Italic modifyText={modifyText} setTextContent={setTextContent} />
          <InsertOrderedList modifyText={modifyText} setTextContent={setTextContent} />
          <InsertUnorderedList modifyText={modifyText} setTextContent={setTextContent} />
        </div>
      </motion.div>
    </div>
  );
};

export default RichText;
