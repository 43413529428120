const Header = (props) => {
  const { emailEditorEl } = props;

  const exportHTML = () => {
    const html = emailEditorEl.current.exportHtml();
    console.log('DEBUG:: html', html);
    const blob = new Blob([html], { type: "text/html" });
    const a = document.createElement("a");
    a.download = "email.html";
    a.href = URL.createObjectURL(blob);
    a.click();
  };

  const viewJson = () => {
    const getBodySettings = emailEditorEl.current.getBodySettings();

    console.log(getBodySettings)
  }

  return (
    <div className="dashboard-header flex">
      <div style={{marginLeft: '15px', fontSize: '18px', fontWeight: 600}}>
        Minimal <span style={{fontSize: '12px', color: 'gray'}}>Mail Builder</span>
      </div>
      <div>
      <button className="dashboard-header-subtitle" onClick={exportHTML}>
        Download HTML
      </button>
      <button className="dashboard-header-subtitle" onClick={viewJson}>
        Get Json Setting {"{..}"}
      </button>
      </div>
    </div>
  );
};

export default Header;
